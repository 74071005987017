
import DicomTagConfigDetailsDialog from "../dicoms/DicomTagConfigDetailsDialog.vue"
import { computed, ref } from "vue"
import OrganizationService from "../../../services/OrganizationService"
import { useRoute, useRouter } from "vue-router"
import AddDicomTagConfig from "../dicoms/AddDicomTagConfig.vue"
import { useConfirm } from "primevue/useconfirm"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default {
  name: "ComputerDicomsList",
  components: { AddDicomTagConfig, DicomTagConfigDetailsDialog, PrimaryButton },
  props: {},
  setup() {
    const route = useRoute()
    const showDicom = ref(false)
    const showAdd = ref(false)
    const dicomTagConfigs = ref()
    const selectedConfig = ref()
    const orgsService = OrganizationService.getInstance()
    const computerId = computed(() => route.params.computer_id as string)
    const organizationId = computed(() => route.params.organization_id as string)
    const confirm = useConfirm()
    const windowWidth = ref(window.innerWidth)
    const scrollable = computed(() => windowWidth.value > 960)

    loadDicomTagConfigs()

    function loadDicomTagConfigs() {
      showDicom.value = false
      showAdd.value = false
      orgsService.getDicomTagConfigsForOrg(organizationId.value).then((result) => {
        dicomTagConfigs.value = result.data
      })
    }

    function showConfig(configData: any) {
      selectedConfig.value = configData
      showDicom.value = true
    }

    function deleteConfig(event: any, configId: string) {
      confirm.require({
        target: event.currentTarget,
        message: "Do you want to delete this DicomTagConfig ?",
        accept: () => {
          orgsService.deleteDicomTagConfig(configId).then(() => {
            loadDicomTagConfigs()
          })
        },
      })
    }

    let frameUrl = ref("https://vsol.slab.com/embed/dicom-tags-configuration-dar83fcx")

    return {
      showDicom,
      dicomTagConfigs,
      loadDicomTagConfigs,
      showConfig,
      selectedConfig,
      showAdd,
      computerId,
      deleteConfig,
      scrollable,
      organizationId,
      frameUrl,
    }
  },
}
