
import { reactive, ref } from "vue"
import OrganizationService from "../../../services/OrganizationService"
import InputNumber from "primevue/inputnumber"
import DicomTagRulesRow from "./DicomTagRulesRow.vue"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default {
  name: "ComputerDicomDialog",
  props: {
    dicomTagConfig: {
      type: Object,
      default: null,
    },
  },
  emits: ["config-changed"],
  setup(props: any, { emit }: any) {
    const orgsService = OrganizationService.getInstance()
    const form = ref({
      alias: "",
      target: "",
    })
    const rules = ref()
    const addRuleForm = reactive({
      tag: "",
      value: "",
      operation: "",
      priority: 0,
    })

    const operationOptions = ref(["ADD", "REMOVE", "COPY"])

    loadDicomTagConfig()

    function loadDicomTagConfig() {
      form.value.alias = props.dicomTagConfig.alias
      form.value.target = props.dicomTagConfig.target
    }

    getRules()

    function getRules() {
      orgsService.getDicomTagConfig(props.dicomTagConfig.id).then((result) => {
        rules.value = result.data.rules
      })
    }

    function addRule() {
      orgsService.addDicomRules(props.dicomTagConfig.id, addRuleForm).then(() => {
        getRules()
      })
    }

    function saveChanges() {
      orgsService.updateDicomTagConfigs(props.dicomTagConfig.id, form.value).then(() => {
        emit("config-changed")
      })
    }

    return {
      saveChanges,
      form,
      rules,
      addRule,
      addRuleForm,
      operationOptions,
      getRules,
    }
  },
  components: { InputNumber, DicomTagRulesRow, PrimaryButton },
}
