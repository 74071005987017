
import OrganizationService from "../../../services/OrganizationService"
import InputNumber from "primevue/inputnumber"
import { useConfirm } from "primevue/useconfirm"
import { reactive, ref } from "vue"

export default {
  props: {
    dicomTagConfig: {
      type: Object,
      default: null,
    },
  },
  emits: ["tag-changed"],
  setup(props: any, { emit }: any) {
    const orgsService = OrganizationService.getInstance()
    const confirm = useConfirm()
    const editToggle = ref(false)
    const editTagForm = reactive({
      tag: "",
      value: "",
      operation: "",
      priority: 0,
    })
    const operationOptions = ref(["ADD", "REMOVE", "COPY"])
    function deleteDicomTag(event: any, data: string) {
      confirm.require({
        target: event.currentTarget,
        message: "Do you want to delete this rule ?",
        accept: () => {
          orgsService.deleteDicomRule(data).then(() => {
            emit("tag-changed")
          })
        },
      })
    }
    function updateDicomTag(
      dataId: string,
      dataTag: string,
      dataValue: string,
      dataOperation: string,
      dataPriority: number
    ) {
      if (editToggle.value) {
        orgsService.updateDicomRule(dataId, editTagForm).then(() => {
          editToggle.value = false
          emit("tag-changed")
        })
      }
      editTagForm.tag = dataTag
      editTagForm.value = dataValue
      editTagForm.operation = dataOperation
      editTagForm.priority = dataPriority
      editToggle.value = !editToggle.value
    }
    return {
      updateDicomTag,
      deleteDicomTag,
      editTagForm,
      editToggle,
      operationOptions,
    }
  },
  components: { InputNumber },
}
