
import { reactive, ref } from "vue"
import OrganizationService from "../../../services/OrganizationService"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default {
  name: "AddDicomTagConfig",
  props: {
    organizationId: {
      type: String,
    },
    dae: {
      type: Object,
    },
  },
  emits: ["config-added"],
  setup(props: any, { emit }: any) {
    const orgsService = OrganizationService.getInstance()
    const form = reactive({
      alias: "",
      target: "",
      daeId: props.dae ? props.dae.id : null,
    })
    const targetOptions = reactive([])
    checkPurpose()
    function checkPurpose() {
      targetOptions.length = 0
      if (props.dae) {
        if (props.dae.purpose === "STORE_DCM") {
          targetOptions.push({
            label: "Outgoing Store",
            key: "OUTGOING_STORE",
          })
          form.target = "OUTGOING_STORE"
        } else {
          targetOptions.push({
            label: "Outgoing MWL",
            key: "OUTGOING_MWL",
          })
          form.target = "OUTGOING_MWL"
        }
      } else {
        targetOptions.push(
          {
            label: "Outgoing Store",
            key: "OUTGOING_STORE",
          },
          {
            label: "Outgoing MWL",
            key: "OUTGOING_MWL",
          },
          {
            label: "Eos Export",
            key: "EOS_EXPORT",
          },
          {
            label: "Incoming Store",
            key: "INCOMING_STORE",
          }
        )
        form.target = "OUTGOING_STORE"
      }
    }
    function addTagConfig() {
      if (props.dae && props.dae.computerId) {
        orgsService.addDicomTagConfigsForComputer(props.dae.computerId as string, form).then(() => {
          emit("config-added")
        })
      } else {
        orgsService.addDicomTagConfigsForOrg(props.organizationId as string, form).then(() => {
          emit("config-added")
        })
      }
    }
    return {
      addTagConfig,
      form,
      targetOptions,
    }
  },
  components: { PrimaryButton },
}
