import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d0e4fbf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tagColumn" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "valueColumn" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "operationColumn" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "priorityColumn" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "buttonsColumn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("td", _hoisted_1, [
      ($setup.editToggle)
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            modelValue: $setup.editTagForm.tag,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.editTagForm.tag) = $event))
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString($props.dicomTagConfig.tag), 1))
    ]),
    _createElementVNode("td", _hoisted_3, [
      ($setup.editToggle)
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            modelValue: $setup.editTagForm.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.editTagForm.value) = $event))
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("label", _hoisted_4, _toDisplayString($props.dicomTagConfig.value), 1))
    ]),
    _createElementVNode("td", _hoisted_5, [
      ($setup.editToggle)
        ? (_openBlock(), _createBlock(_component_Dropdown, {
            key: 0,
            modelValue: $setup.editTagForm.operation,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.editTagForm.operation) = $event)),
            options: $setup.operationOptions
          }, null, 8, ["modelValue", "options"]))
        : (_openBlock(), _createElementBlock("label", _hoisted_6, _toDisplayString($props.dicomTagConfig.operation), 1))
    ]),
    _createElementVNode("td", _hoisted_7, [
      ($setup.editToggle)
        ? (_openBlock(), _createBlock(_component_InputNumber, {
            key: 0,
            modelValue: $setup.editTagForm.priority,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.editTagForm.priority) = $event))
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("label", _hoisted_8, _toDisplayString($props.dicomTagConfig.priority), 1))
    ]),
    _createElementVNode("td", _hoisted_9, [
      _createVNode(_component_Button, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (
        $setup.updateDicomTag(
          $props.dicomTagConfig.id,
          $props.dicomTagConfig.tag,
          $props.dicomTagConfig.value,
          $props.dicomTagConfig.operation,
          $props.dicomTagConfig.priority
        )
      )),
        class: "p-button-text flex justify-content-center"
      }, {
        default: _withCtx(() => [
          (!$setup.editToggle)
            ? (_openBlock(), _createBlock(_component_mdicon, {
                key: 0,
                name: "pencil"
              }))
            : (_openBlock(), _createBlock(_component_mdicon, {
                key: 1,
                name: "content-save"
              }))
        ]),
        _: 1
      }),
      _createVNode(_component_Button, {
        onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.deleteDicomTag($event, $props.dicomTagConfig.id))),
        class: "p-button-danger p-button-text"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_mdicon, { name: "delete" })
        ]),
        _: 1
      })
    ])
  ], 64))
}